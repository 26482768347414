import React, { useEffect, useState } from "react";
import { Typography, Box, Stack } from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import { inAppWalletAdapter, useConnection } from "../Solana.utils";
import {
  Metaplex,
  keypairIdentity,
  bundlrStorage,
} from "@metaplex-foundation/js";
import SnackBar from "./SnackBar";
import "./Bar.styles.css";
import Logo from "../Assets/Logo.png";
import { create_nft, get_uri } from "../Api/Api";

export default function CreateAssetDialog() {
  const connection = useConnection();
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const [SuccessUI, setSuccessUI] = useState(false);
  const [count, setCount] = useState(5);

  const { jwt, userid, studid, certid, uriid } = useParams();

  const CreateDigital = (mintAddress, name) => {
    const sendData = new FormData();
    sendData.append("loginUserId", userid);
    sendData.append("certificateId", certid);
    sendData.append("studentId", studid);
    sendData.append("nftAddress", mintAddress);
    sendData.append("nftName", name);
    axios({
      method: "POST",
      url: create_nft,
      data: sendData,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
      .then((res) => {
        if (!res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(true);
          setColor(true);
          setSuccessUI(true);
          const timer = setInterval(() => {
            setCount((prevCount) => {
              if (prevCount === 1) {
                clearInterval(timer);
                sessionStorage.removeItem("nftCreated");
                window.history.back();
              }
              return prevCount - 1;
            });
          }, 1000);
        } else {
          setOpen(true);
          setStatus(false);
          setColor(false);
          setMessage("Error encountered while storing NFT on the server");
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  };

  const handleCreateNFT = async () => {
    try {
      const keypair = await inAppWalletAdapter.getKeyPair();
      const metaplex = Metaplex.make(connection)
        .use(keypairIdentity(keypair))
        .use(
          bundlrStorage({
            address: "https://devnet.bundlr.network",
            providerUrl: "https://api.devnet.solana.com",
            timeout: 60000,
          })
        );
      const serverData = new FormData();
      serverData.append("loginUserId", userid);
      serverData.append("certificateId", certid);
      serverData.append("studentId", studid);
      serverData.append("uriId", uriid);
      const response = await axios.post(get_uri, serverData, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      if (!response.data.error && response.data.data.uriLink) {
        const metadataUri = response.data.data.uriLink;
        const { nft } = await metaplex.nfts().create(
          {
            uri: metadataUri,
            name: response.data.data.nftName,
            sellerFeeBasisPoints: 0,
          },
          { commitment: "finalized" }
        );
        CreateDigital(nft.address.toString(), response.data.data.nftName);
      } else {
        setOpen(true);
        setStatus(false);
        setColor(false);
        setMessage("Error encountered while storing NFT on the server");
      }
    } catch (error) {
      setMessage(error.message);
      setOpen(true);
      setStatus(false);
      setColor(false);
    }
  };

  useEffect(() => {
    const nftCreated = sessionStorage.getItem("nftCreated");
    if (!nftCreated) {
      handleCreateNFT();
    }
  }, []);

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <SnackBar
        open={open}
        message={message}
        setOpen={setOpen}
        status={status}
        color={color}
      />
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <img
          src={Logo}
          alt="logo"
          style={{ width: 150, height: 100, objectFit: "contain" }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          transform: "translateY(-50%)",
        }}
      >
        <Box textAlign="center">
          {SuccessUI ? (
            <Stack
              spacing={1}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Typography id="successtext" variant="subtitle1" fontWeight={600}>
                Your Digital Milestone is Successfully Created
              </Typography>
              <button class="button-63" onClick={() => window.history.back()}>
                Close
              </button>
              <Typography color="#616e80" variant="caption" fontWeight={600}>
                Window will be closed in <span>{count}</span> seconds
              </Typography>
            </Stack>
          ) : (
            <div id="SqCo">
              <p>
                Crafting Your Personalized Journey,
                <br />
                Just a Moment!
                <span className="point one"> .</span>
                <span className="point two">..</span>
                <span className="point three">. </span>
              </p>
              <span className="bloc one"></span>
              <span className="bloc two"></span>
              <span className="bloc three"></span>
              <span className="bloc four"></span>
              <span className="bloc five"></span>
            </div>
          )}
        </Box>
      </Box>
      <Box>
        <Typography textAlign="center" color="#616e80" fontWeight={600}>
          © Dockal {new Date().getFullYear()}. All Rights Reserved.
        </Typography>
      </Box>
    </Box>
  );
}
