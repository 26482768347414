import React from "react";
import { useRoutes } from "react-router-dom";
import CreateAssetDialog from "./Pages/CreateAssetDialog";

export const startUrl = `/`;

function Routes() {
  return useRoutes([
    {
      path: "/:jwt/:userid/:studid/:certid/:uriid",
      element: <CreateAssetDialog />,
    },
  ]);
}

export default Routes;
