import React, { useMemo } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { clusterApiUrl } from "@solana/web3.js";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { inAppWalletAdapter } from "./Solana.utils";
import Routes from "./Routes";
require("@solana/wallet-adapter-react-ui/styles.css");

function App() {
  const network = process.env.REACT_APP_SOLANA_NETWORK || "devnet";

  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(() => [inAppWalletAdapter], []);

  let theme = createTheme({
    palette: {
      primary: {
        main: "#09628e",
      },
      secondary: {
        main: "#343434",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletDialogProvider>
            <Router>
              <Routes />
            </Router>
          </WalletDialogProvider>
        </WalletProvider>
      </ConnectionProvider>
    </ThemeProvider>
  );
}

export default App;
